<div class="data-loading" >
    <svg class="dataspinner" viewBox="25 25 50 50" *ngIf="!newsletterAddresses" [@fade]>
        <circle class="path" cx="50" cy="50" r="20" fill="none" stroke-width="2" stroke-miterlimit="10"/>
    </svg>
</div>

<div *ngIf="newsletterAddresses" [@fade]>
    <div class="error">
        Das Verschicken von Newslettern funktioniert aktuell nicht mehr. Falls dringend n&ouml;tig, bitte melden.
    </div>

    <ul class="list-group">
        <li class="list-group-item list-header">
                <span>Newsletter verschicken</span>
        </li>
        <li class="list-group-item" *ngIf="!showInformation">


        <ul class="list-group" *ngIf="newsletterAddresses" [@fade]>
            <li class="list-group-item list-header">
                    <span>Newsletter Adressen</span>
            </li>
            <div>
                <li class="list-group-item list-group-item-action" [ngClass]="{'list-group-item-primary': add.status}" *ngFor="let add of newsletterAddresses" (click)="add.status=!add.status;selectAddress(add.address_id);">{{add.address_mail}}</li>
            </div>
        </ul>
            <jodit-editor [(ngModel)]="templateText" (onBlur)="textReplaceTags()"
            [config]="{buttons: 'bold,underline,italic,outdent,indent,font,link,undo,redo,fullsize', buttonsMD: 'bold,underline,italic,outdent,indent,font,link,undo,redo,fullsize', buttonsSM: 'bold,underline,italic,outdent,indent,font,link,undo,redo,fullsize', buttonsXS: 'bold,underline,italic,outdent,indent,font,link,undo,redo,fullsize',showWordsCounter: false,spellcheck: false,toolbarSticky: false,showCharsCounter: false,showXPathInStatusbar: false,disablePlugins: 'autofocus,backspace,imageProcessor,imageProperties,sticky,stat,xpath'}"></jodit-editor>

            <div class="distance"></div>

            <button class="btn btn-success" (click)="saveTemplate()">
                Als Template speichern
            </button>
            <button class="btn btn-danger" (click)="showSendNewsletter=true">
                Newsletter verschicken
            </button>
            <button class="btn btn-success btn-space" (click)="selectAllAddresses()">
                Alle Adressen ausw&auml;hlen
            </button>
            <button class="btn btn-danger" (click)="showDeleteAddresses=true">
                Ausgew&auml;hlte Adressen l&ouml;schen
            </button>
            <div *ngIf="showDeleteAddresses" class="error">
                Achtung: Du bist dabei, die ausgew&auml;hlten Adressen zu l&ouml;schen
                Klicke <u><span style="cursor:pointer" (click)="deleteAddresses()">hier</span></u> wenn du sicher bist. 
                Falls nicht, klicke <u><span style="cursor:pointer" (click)="showDeleteAddresses=false">hier</span></u>
            </div>
            
            <div *ngIf="showSendNewsletter" class="error" style="background-color:green;">
                Bitte &uuml;berpr&uuml;fe, ob der Newsletter korrekt ist und an alle gew&auml;hlten Adressen verschickt werden soll.
                Klicke <u><span style="cursor:pointer" (click)="sendNewsletter()">hier</span></u> falls du sicher bist. 
                Falls nicht, klicke <u><span style="cursor:pointer" (click)="showSendNewsletter=false">hier</span></u>
            </div>
        </li>
    </ul>
</div>