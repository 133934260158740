<div id="doorman"  [@doormanAnimation]="doormanState" (@doormanAnimation.done)="doormanAnimationDone($event)">
    <div id="loader_logo" [@loaderLogoAnimation]="loaderLogoState" (@loaderLogoAnimation.done)="loaderLogoAnimationDone($event)"></div>
    <div class="doorman_container" id="doorman_container" *ngIf="frontendService.booklistLoaded" [@doormanContainerAnimation]="frontendService.booklistLoaded">
        <div *ngFor="let book of frontendService.booklist; last as isLast; even as isEven; index as index">
            <div class="doorman_container_book"
                [ngClass]="{'doorman_container_book_full':(isLast&&isEven), 'doorman_container_book_last':(isLast || (index==frontendService.booklist.length-2 && isEven)), 'doorman_container_book_notpublic': book.public==0}"
                (click)="doormanBookClick(book)" (mouseover)="doormanImageLoop(book.bookid)">
                <div class="doorman_container_book_image" [@doormanImageFadeAnimation]="book.imageState1" [ngStyle]="{'background-image':'url(\'' + book.image1 + '\')'}"></div>
                <div class="doorman_container_book_image" [@doormanImageFadeAnimation]="book.imageState2" [ngStyle]="{'background-image':'url(\'' + book.image2 + '\')'}" style="opacity:0"></div>
            </div>
        </div>
        <div *ngFor="let book of frontendService.booklist; last as isLast; odd as isOdd; index as index" class="doorman_container_book_container_text"
        [style.left]="frontendService.mobileVersion ? '0' : isOdd*50+'%'"
        [style.top]="frontendService.mobileVersion ? index*93+'vh' : floor(index/2)*((frontendService.windowHeight-60))+'px'">
            <div class="doorman_container_book_title" (mouseover)="!frontendService.mobileVersion && book.textState='show'"
                (mouseout)="book.textState='hide'" [ngStyle]="{'cursor': book.public==1 ? 'pointer' : 'default' }"
                (click)="doormanBookCheckText(book.bookid);doormanBookClickTitle(book)" [attr.id]="'doorman_book'+book.bookid">
                <span class="doorman_container_book_title_text">{{book.bookname}}</span>
                <span class="doorman_container_book_title_strut"></span>
            </div>
            <div class="doorman_container_book_text" (mouseover)="book.textState='show'"
            (mouseout)="book.textState='hide'" [@doormanBookTextAnimation]="book.textState"
            (click)="doormanBookClickText(book)" [attr.id]="'doorman_book_text'+book.bookid">                    
                <div class="doorman_container_book_text_inner" [innerHTML]="book.bookblurb | sanitizeHtml"></div>
            </div>
        </div>
    </div>
</div>